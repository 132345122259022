var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Header'),_c('section',{staticClass:"front-signup"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 col-lg-5"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"signup-container",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"keydown":_vm.clearError}},[_c('h4',{staticClass:"signup-title"},[_vm._v("რეგისტრაცია")]),_c('div',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"singupname"}},[_vm._v("სახელი და გვარი")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"auth-control",attrs:{"type":"text","id":"singupname","placeholder":"სახელი და გვარი"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e()]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"Number","rules":{required: _vm.required, regex: /^[0-9]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"mobile-number"}},[_vm._v("მობილური")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mobile),expression:"form.mobile"}],staticClass:"auth-control",attrs:{"minlength":"9","maxlength":"9","type":"text","id":"mobile-number","placeholder":"xxx xx xx xx"},domProps:{"value":(_vm.form.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "mobile", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v("გთხოვთ მიუთითოთ ნომერი სრულად (სწორი ფორმატით)")]):_vm._e()]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"signup-email"}},[_vm._v("ელ.ფოსტა")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"auth-control",attrs:{"type":"email","id":"signup-email","placeholder":"example@motivation.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e()]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"singnup-password"}},[_vm._v("პაროლი")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"auth-control",attrs:{"type":"password","id":"singnup-password","placeholder":"პაროლი"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),(errors[0] && _vm.form.password.length == 0)?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e(),(_vm.form.password.length >= 1)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"singnup-repassword"}},[_vm._v("გაიმეორეთ პაროლი")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.repassword),expression:"form.repassword"}],staticClass:"auth-control",attrs:{"type":"password","id":"singnup-repassword","placeholder":"გაიმეორე პაროლი"},domProps:{"value":(_vm.form.repassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "repassword", $event.target.value)}}}),(errors[0] && _vm.form.repassword.length == 0)?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e(),(_vm.form.repassword.length >= 1)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"terms","rules":"checked"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.terms),expression:"form.terms"}],staticClass:"custom-control-input me-2",attrs:{"name":"terms","type":"checkbox","id":"terms"},domProps:{"checked":Array.isArray(_vm.form.terms)?_vm._i(_vm.form.terms,null)>-1:(_vm.form.terms)},on:{"change":function($event){var $$a=_vm.form.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "terms", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "terms", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "terms", $$c)}}}}),_c('label',{staticClass:"custom-control-label text-muted mb-0",attrs:{"for":"terms"}},[_vm._v("მე ვეთანხმები პირობებს "),_c('a',{staticClass:"terms",attrs:{"href":"https://dk.ge/terms.pdf","target":"_blank"}},[_c('b',[_vm._v("პირობები")])])]),(errors[0])?_c('span',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"text-center"},[_c('span',[_vm._v("გაქვთ ანგარიში?")]),_c('RouterLink',{attrs:{"to":"/Signin"}},[_vm._v("შესვლა")])],1),_c('button',{staticClass:"btn-auth"},[_vm._v("რეგისტრაცია")])])])]}}])})],1)])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-lg-7 d-none d-lg-block"},[_c('img',{staticClass:"img-fluid",attrs:{"alt":"home image","src":require("../../../assets/image/Signup.png")}})])}]

export { render, staticRenderFns }