var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"login-main"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"on-cancel":_vm.onCancel,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"position-fixed top-0 start-0 end-0 bg-image-login"},[_c('figure',{staticClass:"position-absolute end-0 bottom-0 start-0 mb-0"},[_c('svg',{attrs:{"preserveAspectRatio":"none","xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":"0 0 1921 273"}},[_c('polygon',{attrs:{"fill":"#fff","points":"0,273 1921,273 1921,0 "}})])])]),_c('div',{staticClass:"container-fluid py-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-7 col-lg-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[_vm._m(0),_c('h1',{staticClass:"text-center login-title"},[_vm._v("რეგისტრაცია")]),_c('p',{staticClass:"text-center"},[_vm._v(" გაქვთ უკვე ანგარიში? "),_c('router-link',{attrs:{"to":"/Signin"}},[_vm._v("ავტორიზაცია")])],1),_c('br'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"keydown":_vm.clearError}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label",attrs:{"for":"singupname"}},[_vm._v("თქვენი სახელი გვარი")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","id":"singupname","placeholder":"სახელი გვარი"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})])]),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label",attrs:{"for":"signin-email"}},[_vm._v("თქვენი ელ-ფოსტა")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control form-control-lg",attrs:{"type":"email","id":"signin-email","placeholder":"example@davidkenchadze.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Number","rules":{ required: _vm.required, regex: /^[0-9]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label",attrs:{"for":"mobile-number"}},[_vm._v("თქვენი მობილურის ნომერი")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mobile),expression:"form.mobile"}],staticClass:"form-control form-control-lg",attrs:{"minlength":"9","maxlength":"9","type":"text","id":"mobile-number","placeholder":"თქვენი მობილურის ნომერი: xxx xx xx xx"},domProps:{"value":(_vm.form.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "mobile", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v("გთხოვთ მიუთითოთ ნომერი სრულად (სწორი ფორმატით)")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label",attrs:{"for":"singnup-password"}},[_vm._v("პაროლი")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control form-control-lg",attrs:{"type":"password","id":"singnup-password","placeholder":"პაროლი"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),(errors[0] && _vm.form.password.length == 0)?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e(),(_vm.form.password.length >= 1)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label",attrs:{"for":"singnup-repassword"}},[_vm._v("გაიმეორეთ პაროლი")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.repassword),expression:"form.repassword"}],staticClass:"form-control form-control-lg",attrs:{"type":"password","id":"singnup-repassword","placeholder":"გაიმეორე პაროლი"},domProps:{"value":(_vm.form.repassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "repassword", $event.target.value)}}}),(errors[0] && _vm.form.repassword.length == 0)?_c('span',{staticClass:"text-danger"},[_vm._v("აუცილებელი ველი")]):_vm._e(),(_vm.form.repassword.length >= 1)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"pass-checkbox"}}),_c('label',{staticClass:"custom-control-label text-muted",attrs:{"for":"pass-checkbox"}},[_vm._v("მე ვეთანხმები პირობებს "),_c('a',{attrs:{"href":"https://recruting.dkcapital.ge/rules.pdf","target":"_blank"}},[_c('b',[_vm._v("პირობები")])])])])]),_c('button',{staticClass:"btn btn-lg btn-block btn-primary",attrs:{"type":"submit"}},[_vm._v("რეგისტრაცია")])])]}}])})],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-logo text-center mb-3"},[_c('h1',[_vm._v("DAVID KENCHADZE")])])}]

export { render, staticRenderFns }